import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import './AccessPagesLayout.css';
import { userService } from 'services/user.service';
import { OPTTY_WHITE_SUB_TM_LOGO_URL } from '../../constants/index';
import { TypoGraphy } from '../FormElements';

type Theme = { logo: string; platformName: string; };
const defaultTheme: Theme = { logo: OPTTY_WHITE_SUB_TM_LOGO_URL, platformName: '' };

const AccessPagesLayout: React.FC = (props: { children?: React.ReactNode }) => {
  const { t } = useTranslation();
  const [theme, setTheme] = useState({ logo: '', platformName: '' });
  const { search } = useLocation();

  useEffect(() => {
    if (!theme.logo) {
      const customizer = (theme?: Theme) => (theme?.logo ? theme : defaultTheme);

      const themeBase64 = new URLSearchParams(search).get('theme');
      let theme0: Theme;

      if (themeBase64) {
        theme0 = JSON.parse(Buffer.from(themeBase64!, 'base64').toString());
        setTheme(customizer(theme0));
      } else {
        const currentDomain = window.location.origin;
        const isCustomDomain = currentDomain !== process.env.REACT_APP_PUBLIC_URL;
        if (isCustomDomain) {
          const customDomainEnvVar = `REACT_APP_${window.location.host.replace(/\./g, '_').toUpperCase()}`;
          const authority = process.env[customDomainEnvVar] ?? process.env.REACT_APP_AUTH_URL;
          const authorityWithoutProtocol = authority?.substring((authority?.indexOf(':') ?? 0) + 3);
          userService.fetchSettings(authorityWithoutProtocol!).then((theme0) => setTheme(customizer(theme0)));
        } else {
          setTheme(customizer());
        }
      }
    }
  }, []);

  return (
    <>
      <main className="main-content horizontally-centered vertically-centered">
        <div className="pane-wrapper">
          <section className="left-pane image-decorated">
            <aside className="adjusted-for-contrast positioned-midway">
              <TypoGraphy
                className="text-centered"
                level={2}
              >
                <img
                  alt="logo"
                  // height="120px"
                  src={theme.logo}
                  width="auto"
                  style={{ maxWidth: '300px', maxHeight: '120px' }}
                />
              </TypoGraphy>
              <p className="blocktext text-centered">
                {t('accessPageLeftPaneText')} {/* t('accessPageLeftPaneTextBelow') */}
              </p>
              <p className="blocktext text-centered">
                {/* {t('accessPageLeftPaneMottoText')} */}
              </p>
            </aside>
          </section>
          <section className="right-pane">
            {/* <div className="backBar"> */}
            {/* <CgChevronLeft */}
            {/* onClick={() => history.goBack()} */}
            {/* size="2.5em" */}
            {/* /> */}
            {/* </div> */}
            <div className="content">
              <article className="form-box">{props.children}</article>
            </div>
          </section>
        </div>
      </main>
    </>
  );
};

export default AccessPagesLayout;
