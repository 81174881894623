import React, { useState, useEffect, useRef } from 'react';
import { Tooltip } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { ArrowRight } from '@material-ui/icons';
import { paymentProviderConfigs } from 'config/Payment-Management-Configs/payment-provider-configs';
import {
 API_KEY, API_SECRET, PaymentProviderConfigType, PIPE_SEPARATOR,
} from 'config/Payment-Management-Configs/payment-provider-types';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import { Button, SingleLineTextInput, FormControlBox } from './FormElements';
import './MerchantForm.css';
import { ButtonDisplay } from './FormElements/Button';
import { InputStyling } from './FormElements/SingleLineTextInput';

const DynamicMerchantForm = (props: any) => {
  const {
    providerName, stateValues, setStateValues, handleAPISubmit, ischannel, selectedChannel, setSelectedChannel, isButtonLoading,
  } = props;
  const { t } = useTranslation();

  const providerFields: PaymentProviderConfigType = paymentProviderConfigs;
  type ProviderKey = keyof PaymentProviderConfigType;

  const [formValues, setFormValues] = useState<Record<string, string>>(() => {
    const initialValues: any = {};
    providerFields[providerName].forEach((field) => {
      initialValues[field.name] = stateValues.apiKey && stateValues.apiSecret ? '**********' : '';
    });
    initialValues.touched = false;
    initialValues.errorMessage = '';
    return initialValues;
  });

  useEffect(() => {
    getFinalizedValue(formValues, providerName);
  }, [formValues]);

  const isFormValid = () => {
    const optionalFields = providerFields[providerName].filter((field) => !field.isRequired).map((field) => field.name);
    const isFormDataValid = Object.values(formValues).every((value, index) => {
      const key = Object.keys(formValues)[index];
      if (optionalFields.includes(key)) return true;
      if (key === 'errorMessage') return !value;
      return !!value;
    });
    return isFormDataValid && !isButtonLoading;
  };

  const getFinalizedValue = async (
    formValue: Record<string, string>,
    selectedProvider: ProviderKey,
  ) => {
    const formFields = providerFields[selectedProvider];
    const publicKey = formFields
    .filter((record) => record.keyType !== API_SECRET)
    .map((record) => formValue[record.name])
    .join(PIPE_SEPARATOR);

    const privateKey = formFields
    .filter((record) => record.keyType !== API_KEY)
    .map((record) => formValue[record.name])
    .join(PIPE_SEPARATOR);

    setStateValues((prevState: any) => ({
      ...prevState,
      apiKey: publicKey,
      secret: privateKey,
      apiSecret: privateKey,
      errorMessage: '',
    }));
  };

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setFormValues((prevState: any) => ({
      ...prevState,
      [name]: value,
      touched: true,
      errorMessage: '',
    }));
  };

  return props.selectedBnpl ? (
    <>
      <div className="form-title">1. {t('Enter Credentials')}</div>
      <form onSubmit={handleAPISubmit}>
        {providerFields[providerName].map((field) => (
          <FormControlBox
            key={field.name}
            className="text-bolder"
            controlClassName=""
            htmlFor={field.name}
            labelText={t(field.label)}
            isRequired={field.isRequired}
          >
            <Tooltip
              title={(field.infoGuide || 'Please enter credentials')}
            >
              <ErrorOutlineIcon style={{ cursor: 'pointer', fontSize: '18px', marginLeft: '5px' }} />
            </Tooltip>
            <SingleLineTextInput
              data-testid={`bnpl-${field.name}`}
              hasError={stateValues.errorMessage !== '' && formValues[field.name] !== ''}
              hasSuccess={stateValues.success && formValues[field.name] !== ''}
              id={field.name}
              name={field.name}
              onChange={(e) => handleChange(e)}
              placeholder={t(field.label)}
              required={field.isRequired}
              styling={InputStyling.Vanilla}
              type={field.type}
              value={formValues[field.name] || ''}
            />
          </FormControlBox>
        ))}
        {stateValues.errorMessage !== '' && (
          <div className="form-error">{stateValues.errorMessage}</div>
        )}
        <div>
          <div className="manage-form-submit">
            <Button
              disabled={!isFormValid()}
              display={ButtonDisplay.NextButton}
              text={(
                <>
                  {t('Test Connection')} <ArrowRight />
                </>
                )}
              type="submit"
            />
          </div>
        </div>
      </form>
    </>
  ) : null;
};

export default DynamicMerchantForm;
