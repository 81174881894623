export const roles = {
  ADMIN: 'admin',
  MERCHANT: 'merchant',
  PARENT: 'parent',
  GRANDPARENT: 'grandparent',
};

export const transactionStatus = {
  initiated: 'INITIATED',
  pending: 'PENDING',
  successful: 'SUCCESSFUL',
  declined: 'DECLINED',
  canceled: 'CANCELED',
  error: 'ERROR',
};

export const getPaidStatus = {
  Paid: 'PAID',
  UnPaid: 'UNPAID',
  Cancelled: 'CANCELED',
  Refunded: 'REFUNDED',
  Declined: 'DECLINED',
};

export const getPaidImportCsvStatus = {
  successful: 'Successful',
  error: 'Error',
  pending: 'Pending',
};